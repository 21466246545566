import { createApp } from "vue";
import App from "./App.vue";
import jquery from "jquery";
import "bootstrap";
import axios from "axios";
import router from "./router/index";
import store from "./store/index";
import i18n from "./i18n";
import Multiselect from "vue-multiselect";
import Vue3CountryIntl from "vue3-country-intl";
// import css
import "vue3-country-intl/lib/vue3-country-intl.css";

const app = createApp(App);
app.use(jquery);
app.use(store);
app.use(i18n);
app.component(Vue3CountryIntl.name, Vue3CountryIntl);
// router.beforeEach((to, from, next) => {
//   window.scrollTo(0, 0);
//   next();
// });
// Define the number formatting function
app.config.globalProperties.$formatNumber = function (number) {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
};
// eslint-disable-next-line vue/multi-word-component-names
app.component("multiselect", Multiselect);
app.config.globalProperties.$http = axios;
axios.interceptors.request.use((req) => {
  req.headers["Accept-Language"] = localStorage.Lang || "en";
  req.headers.authorization = "Bearer " + localStorage.merhabaToken;
  return req;
});
axios.interceptors.response.use(function (response) {
  return response;
});
app.config.globalProperties.$hostUrl = process.env.VUE_APP_BACKEND_SERVER;
app.use(router).mount("#app");
