import { createStore } from "vuex";

export default createStore({
  state: {
    cities: [],
  },
  getters: {},
  actions: {
    // Define a method to fetch cities
    fetchCities({ commit }, { $http, $hostUrl }) {
      $http
        .get(`${$hostUrl}cities`)
        .then((response) => {
          // Commit a mutation to update the state with the fetched cities
          commit("setCities", response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching cities:", error);
        });
    },
    // ... other actions
  },
  mutations: {
    // Mutation to set cities in the state
    setCities(state, cities) {
      state.cities = cities;
    },
  },
});
