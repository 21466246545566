<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="header-top">
    <div class="container">
      <div class="row dp-flex">
        <div class="col-md-8 col-sm-6">
          <div class="header-top-left">
            <p>
              <span
                ><router-link to="/about">
                  {{ $t("message.about") }}</router-link
                >
                |
              </span>
              <span>
                <router-link to="/our-services">
                  {{ $t("message.services") }}</router-link
                >
              </span>
              <!-- <span>FAQ</span> -->
            </p>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="header-top-right text-md-end text-center">
            <ul v-if="!logged">
              <li>
                <router-link to="/log-in">{{
                  $t("message.login")
                }}</router-link>
              </li>
              <li>
                <router-link to="/sign-up">{{
                  $t("message.register")
                }}</router-link>
              </li>
            </ul>
            <ul v-else>
              <li class="">
                <router-link to="/profile">
                  <i class="fas fa-user"></i>Profile</router-link
                >
              </li>
              <li>
                <span @click="logout">
                  <i class="fas fa-power-off text-danger"></i>
                  logout</span
                >
              </li>
              <!-- <li><router-link to="/sign-up">signup</router-link></li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <header class="top">
    <!-- Header Area Start -->
    <div class="header-area two header-sticky">
      <div class="container nav-container">
        <div class="row dp-flex">
          <div class="col-md-1 col-sm-5 col-1">
            <div class="logo">
              <router-link to="/"
                ><img
                  class="logo-image"
                  src="../assets/img/logo/logo1.webp"
                  alt="eduhome"
              /></router-link>
              <!-- <div class="title2">
                <span class="main-color">Merhaba</span>Gate
              </div> -->
            </div>
          </div>
          <div class="col-md-11 col-sm-10 col-11 text-center">
            <div class="content-wrapper">
              <!-- Main Menu Start -->
              <div class="main-menu d-none d-md-block">
                <nav>
                  <ul class="ul-border">
                    <li>
                      <router-link to="/">{{ $t("message.home") }}</router-link>
                    </li>
                    <li class="all-prog-cont">
                      <router-link to="/programs">
                        <span>{{ $t("message.programs") }} </span></router-link
                      >

                      <router-link to="/bachelor">
                        {{ $t("message.bachelor") }}</router-link
                      >

                      <router-link to="/master">
                        {{ $t("message.master") }}</router-link
                      >

                      <router-link to="/ph.d">
                        {{ $t("message.phd") }}</router-link
                      >

                      <router-link to="/associate">
                        {{ $t("message.associate") }}</router-link
                      >
                    </li>

                    <li>
                      <router-link to="/universities">
                        {{ $t("message.universities") }}</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/articles">{{
                        $t("message.articles")
                      }}</router-link>
                    </li>
                    <li class="pointer" v-if="$root.$i18n.locale === 'ar'">
                      <span class="lang-li" @click="changeLocal('en')"
                        >English<img
                          width="27"
                          class="lang-img"
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg/2560px-Flag_of_the_United_Kingdom_%283-5%29.svg.png"
                      /></span>
                    </li>
                    <li class="pointer" v-if="$root.$i18n.locale === 'en'">
                      <span class="lang-li ar-txt" @click="changeLocal('ar')">
                        العربية
                        <img
                          width="27"
                          class="lang-img"
                          src="https://qph.cf2.quoracdn.net/main-qimg-711158b2ed1b9b161895d3c46b3cfa99-lq"
                        />
                      </span>
                    </li>
                    <li>
                      <div class="button-box text-end head-btn">
                        <button
                          @click="goApply()"
                          type="submit"
                          class="default-btn mtp mbt-2"
                        >
                          {{ $t("message.apply") }}
                          <i
                            :class="{
                              'fas fa-chevron-right':
                                $root.$i18n.locale === 'en',
                              'fas fa-chevron-left':
                                $root.$i18n.locale === 'ar',
                            }"
                          ></i>
                        </button>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
              <!--Search Form Start-->
              <!-- <div class="search-btn">
                <ul @click="showSearch" class="header-search search-toggle">
                  <li class="search-menu">
                    <i class="fa fa-search"></i>
                  </li>
                </ul>
                <div class="search">
                  <div class="search-form">
                    <form id="search-form" action="#">
                      <input
                        type="search"
                        placeholder="Search here..."
                        name="search"
                      />
                      <button type="submit">
                        <span><i class="fa fa-search"></i></span>
                      </button>
                    </form>
                  </div>
                </div>
              </div> -->
              <!--End of Search Form-->
              <!-- Main Menu End -->
            </div>
          </div>
          <div class="col-12 d-block d-md-none">
            <div class="mobile-menu one">
              <Slide
                noOverlay
                :closeOnNavigation="true"
                :width="260"
                :menu-wrapper="'.main-menu nav'"
                :menu="'.mobile-menu'"
              >
                <!-- Your menu content here -->
                <div class="">
                  <nav>
                    <ul>
                      <li>
                        <router-link to="/">Home</router-link>
                      </li>
                      <li>
                        <router-link to="/programs">All Programs</router-link>
                      </li>
                      <li>
                        <router-link to="/bachelor">Bachelor</router-link>
                      </li>
                      <li>
                        <router-link to="/master">Master</router-link>
                      </li>

                      <li><router-link to="/ph.d">Ph.D</router-link></li>
                      <li>
                        <router-link to="/associate">Associate</router-link>
                      </li>
                      <li>
                        <router-link to="/universities"
                          >Universities</router-link
                        >
                      </li>

                      <li>
                        <router-link to="/articles">Articles</router-link>
                      </li>
                      <li>
                        <div class="button-box text-center">
                          <button
                            @click="goApply()"
                            type="submit"
                            class="default-btn mtp mbt-2"
                          >
                            Apply Now
                          </button>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- Header Area End -->
</template>
<script>
import Swal from "sweetalert2";
import { Slide } from "vue3-burger-menu";
export default {
  components: {
    Slide,
  },
  date() {
    return {
      logged: false,
      showSearchBox: false,
    };
  },
  created() {
    localStorage.merhabaToken ? (this.logged = true) : (this.logged = false);
  },
  methods: {
    changeLocal(local) {
      localStorage.Lang = local;
      this.$root.$i18n.locale = localStorage.Lang;
      const dir = local === "ar" ? "rtl" : "ltr";
      const bodyClass = local === "ar" ? "ar" : "en";
      localStorage.dir = dir;
      document.body.setAttribute("dir", dir);
      document.body.className = bodyClass; // Set the class
      document.documentElement.setAttribute("dir", dir);
      location.reload();
    },
    goApply() {
      this.$router.push({
        path: "/apply-now",
      });
    },
    showSearch() {
      this.showSearchBox = true;
      let searchSelector = document.querySelector(".search-toggle");
      if (searchSelector) {
        let searchBox = document.querySelector(".search");
        searchBox.classList.toggle("open");
      }
    },
    logout(e) {
      e.preventDefault();
      const current = this;
      const formData = new FormData();
      formData.append("token", localStorage.merhabaToken);

      current.$http
        .post(`${current.$hostUrl}logout`, formData, {
          headers: {
            Authorization: "Bearer " + localStorage.merhabaToken,
          },
        })
        .then((response) => {
          const visitorId = localStorage.getItem("visitorId"); // get the visitorId
          localStorage.clear(); // clear all items from localStorage
          if (visitorId !== null) {
            localStorage.setItem("visitorId", visitorId);
          }

          Swal.fire({
            position: "top-center",
            icon: "success",
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
          });

          current.$router.push({ path: "/log-in" });
        })
        .catch((error) => {
          if (error.response) {
            const data = error.response.data;
            const errorMessage = data.error
              ? data.message
              : "Something went wrong!";

            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: errorMessage,
              footer: '<a href="#">Why do I have this issue?</a>',
            });
          } else {
            current.$vToastify.error(
              "حدث خطـأ اثناء معالجة الطلب الرجاء المحاولة لاحقا"
            );
          }
        });
    },
  },
};
</script>
