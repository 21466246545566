<template>
  <div>
    <Header v-if="showHeader" />
    <router-view />
    <Footer v-if="showFooter" />
  </div>
</template>

<script>
// import $ from "jquery";
import Header from "@/layout/Header.vue";
import Footer from "@/layout/Footer.vue";
export default {
  name: "App",
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      hasRefParam: false,
      sessionId: "",
      locale: "en",
    };
  },
  watch: {
    $route: {
      handler(to) {
        document.title = "Merhabagate " + "| " + to.meta.title || "Merhabagate";
      },
      immediate: true,
    },
  },
  computed: {
    showHeader() {
      // Determine if the current route requires the navbar
      const excludedPages = ["sitemap.xml"];
      return !excludedPages.includes(this.$route.name);
    },
    showFooter() {
      // Determine if the current route requires the footer
      const excludedPages = ["sitemap.xml"];
      return !excludedPages.includes(this.$route.name);
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const refParam = urlParams.get("ref");
    console.log(refParam, "var");

    // Check if refs parameter is present
    if (refParam) {
      this.hasRefParam = true;
      this.sessionId = this.generateSessionId();
      localStorage.setItem("sessionId", this.sessionId);
      this.sendSession(refParam);
    }
    //lang
    const savedLang = localStorage.Lang || "en";
    this.$i18n.locale = savedLang;
    const dir = savedLang === "ar" ? "rtl" : "ltr";
    const bodyClass = localStorage.Lang === "ar" ? "ar" : "en";
    document.body.setAttribute("dir", dir);
    document.body.className = bodyClass;
    document.documentElement.setAttribute("dir", dir);
  },
  methods: {
    generateSessionId() {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let result = "";

      for (let i = 0; i < 9; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }

      return `${result}`;
    },
    sendSession(refParam) {
      const current = this;
      const formData = new FormData();
      formData.append("session_id", localStorage.sessionId);
      formData.append("referrer_id", refParam);
      current.$http
        .post(`${current.$hostUrl}session`, formData)
        .then(() => {})
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
@import "assets/css/bootstrap.min.css";
@import "assets/css/animate.min.css";
@import "assets/css/meanmenu.css";
@import "assets/css/magnific-popup.css";
/* @import "assets/css/owl.carousel.min.css"; */
@import "assets/css/font-awesome.min.css";
@import "assets/css/et-line-icon.css";
@import "assets/css/reset.css";
@import "assets/css/ionicons.min.css";
@import "assets/css/material-design-iconic-font.min.css";
@import "assets/css/style.css";
@import "assets/css/style2.css";
@import "assets/css/responsive.css";
</style>
<style lang="scss">
@import "assets/css/ar-style.scss";
</style>
