<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <!-- Footer Start -->
  <footer class="footer-area">
    <div class="main-footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="single-widget pr-60">
              <div class="footer-logo">
                <a href="/"
                  ><img
                    src="../assets/img/logo/logo1.webp"
                    width="130"
                    height="130"
                /></a>
                <!-- <div class="title2">
                  <span class="main-color">Merhaba</span>Gate
                </div> -->
              </div>
              <p>
                {{ $t("message.footerTXt") }}
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="single-widget">
              <h3>{{ $t("message.info") }}</h3>
              <ul>
                <!-- <li><a href="#">Universities Calender</a></li> -->
                <li>
                  <router-link to="/articles">
                    {{ $t("message.articles") }}</router-link
                  >
                </li>
                <li>
                  <router-link to="/our-services">
                    {{ $t("message.services") }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/terms-conditions">{{
                    $t("message.terms")
                  }}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 pt-4 pt-lg-0">
            <div class="single-widget">
              <h3>{{ $t("message.links") }}</h3>
              <ul>
                <li>
                  <router-link to="/"> {{ $t("message.home") }}</router-link>
                </li>
                <li>
                  <router-link to="/about">
                    {{ $t("message.about") }}
                  </router-link>
                </li>
                <li>
                  <router-link to="/programs">
                    {{ $t("message.programs") }}</router-link
                  >
                </li>
                <li>
                  <router-link to="/universities">
                    {{ $t("message.universities") }}</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-6 pt-4 pt-lg-0">
            <div class="single-widget">
              <h3>{{ $t("message.contact") }}</h3>
              <p>
                <a href="mailto:info@merhabagate.com">info@merhabagate.com</a
                ><br /><br />
                <a href="mailto:support@merhabagate.com"
                  >support@merhabagate.com</a
                ><br /><br />
                <a href="mailto:admission@merhabagate.com"
                  >admission@merhabagate.com</a
                ><br /><br />
                <a href="mailto:feedback@merhabagate.com"
                  >feedback@merhabagate.com</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer End -->
</template>
