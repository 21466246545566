import { createI18n } from "vue-i18n";
import arMessages from "./locales/ar.json";
import enMessages from "./locales/en.json";

const messages = {
  en: {
    message: enMessages,
  },
  ar: {
    message: arMessages,
  },
};
if (!localStorage.Lang) {
  localStorage.Lang = "en";
  localStorage.dir = "ltr";
}
const i18n = createI18n({
  locale: localStorage.Lang,
  fallbackLocale: "en",
  globalInjection: true,
  direction: "ltr",
  messages,
});
export default i18n;
