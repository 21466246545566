import { createRouter, createWebHistory } from "vue-router";
// import Router from 'vue-router'
const routes = [
  {
    path: "/",
    name: "Home",
    alias: "/home",
    component: () => import("../components/Home"),
    meta: {
      title: "Home",
      page: "home",
    },
  },
  {
    path: "/articles",
    name: "articles",
    component: () => import("../components/Articles"),
    meta: {
      title: "Articles",
      page: "",
    },
  },
  {
    path: "/articles/:slug",
    name: "article details",
    component: () => import("../components/article-details"),
    meta: {
      title: "show article",
      page: "",
    },
  },
  {
    path: "/programs",
    name: "programs",
    component: () => import("../components/Programs"),
    meta: {
      title: "Programs",
      page: "programs",
    },
  },
  {
    path: "/programs/:slug1/:slug2",
    name: "",
    component: () => import("../components/ProgramDetails"),
    meta: {
      title: " Program Details",
      page: "",
    },
  },
  {
    path: "/universities",
    name: "universities",
    component: () => import("../components/universities"),
    meta: {
      title: "Universities",
      page: "universities",
    },
  },
  {
    path: "/universities/:slug/show",
    name: "",
    component: () => import("../components/unversityDetails"),
    meta: {
      title: "University Details",
      page: "",
    },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../components/About"),
    meta: {
      title: " About",
      page: "About",
    },
  },
  {
    path: "/master",
    name: "master",
    component: () => import("../components/Master"),
    meta: {
      title: "Master",
      page: "master",
    },
  },
  {
    path: "/associate",
    name: "associate",
    component: () => import("../components/Associate"),
    meta: {
      title: "Associate",
      page: "associate",
    },
  },
  {
    path: "/bachelor",
    name: "bachelor",
    component: () => import("../components/Bachelor"),
    meta: {
      title: "Bachelor",
      page: "bachelor",
    },
  },
  {
    path: "/Ph.D",
    name: "Ph.D",
    component: () => import("../components/PHD"),
    meta: {
      title: "Ph.D",
      page: "Ph.D",
    },
  },
  {
    path: "/log-in",
    name: "login",
    component: () => import("../components/login"),
    meta: {
      title: " Login ",
      page: "login",
    },
  },
  {
    path: "/sign-up",
    name: "signup",
    component: () => import("../components/Signup"),
    meta: {
      title: " signup ",
      page: "signup",
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("../components/ResetPass"),
    meta: {
      title: "Reset-Password ",
      page: "",
    },
  },
  {
    path: "/confirm-done",
    name: "confirm-done",
    component: () => import("../components/ConfirmDone"),
    meta: {
      title: "Confirm-Done ",
      page: "",
    },
  },
  {
    path: "/verify-auth",
    name: "google-auth",
    component: () => import("../components/google-auth"),
    meta: {
      title: "Google-Auth ",
      page: "",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../components/Profile"),
    meta: {
      title: "Profile",
      page: "",
    },
  },
  {
    path: "/apply",
    name: "apply",
    component: () => import("../components/Apply"),
    meta: {
      title: "Apply",
      page: "",
    },
  },
  {
    path: "/sitemap.xml",
    name: "sitemap.xml",
    component: () => import("../components/sitemapCom"),
    meta: {
      title: "sitemap",
      page: "",
    },
  },
  {
    path: "/apply-now",
    name: "apply-now",
    component: () => import("../components/ApplyNow"),
    meta: {
      title: "Apply Now",
      page: "",
    },
  },
  {
    path: "/terms-conditions",
    name: "terms-conditions",
    component: () => import("../components/terms-conditions"),
    meta: {
      title: " Terms-Conditions",
      page: "terms-conditions",
    },
  },
  {
    path: "/our-services",
    name: "our-services",
    component: () => import("../components/Services"),
    meta: {
      title: "Our-Services",
      page: "our-services",
    },
  },
  {
    path: "/apply-step1",
    name: "apply-step1",
    component: () => import("../components/apply-step1"),
    meta: {
      title: "apply-step1",
      page: "apply-step1",
    },
  },
  // {
  //   path: "/chat",
  //   name: "chat",
  //   component: () => import("../components/Chat"),
  //   meta: {
  //     title: "chat",
  //     page: "chat",
  //   },
  // },
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    component: () => import("../components/404"),
    meta: {
      title: "Not Found",
      page: "notFound",
    },
  },
];
const router = createRouter({
  // linkActiveClass: 'active',
  // linkExactActiveClass: 'active',
  history: createWebHistory(),
  routes,
});
export default router;

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});
